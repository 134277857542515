import React from 'react';
import PropTypes from 'prop-types';

import textStyles from './Text.module.css';

export default function Header({ text }) {
  return <h1 className={textStyles.header}>{text}</h1>;
}

Header.propTypes = {
  text: PropTypes.string,
};
Header.defaultProps = {
  text: '',
};

// export default { Header };
