import React from 'react';
import PropTypes from 'prop-types';
import journeyStyles from './Journey.module.css';
import Header from '../components/text/Text';
import Rating from '../components/rating/Rating';

export default function Journey({ scoreCareDiagnosing, scoreCareTreatment, scoreCareOutcomes }) {
  return (
    <div className={journeyStyles.wrap}>
      <Header text="Member Journey" />
      <p className={journeyStyles.description}>
        A member journey represents the entire sequence of events that a patient experiences with a
        provider.
      </p>
      <div className={journeyStyles.ratingWrap}>
        <Rating header="Diagnosing" score={scoreCareDiagnosing} />
        <Rating header="Treatment Plan" score={scoreCareTreatment} />
        <Rating header="Patient Outcomes" score={scoreCareOutcomes} />
      </div>
    </div>
  );
}

Journey.defaultProps = {
  scoreCareDiagnosing: 0,
  scoreCareOutcomes: 0,
  scoreCareTreatment: 0,
};

Journey.propTypes = {
  scoreCareDiagnosing: PropTypes.number,
  scoreCareOutcomes: PropTypes.number,
  scoreCareTreatment: PropTypes.number,
};
