import React, { useState, useEffect } from 'react';
import { SwitchTransition, CSSTransition } from 'react-transition-group';
import camelcaseKeys from 'camelcase-keys';
import PulseLoader from 'react-spinners/PulseLoader';
import Score from './score/Score';
import Journey from './journey/Journey';
import Cost from './cost/Cost';
import Nav from './components/nav/Nav';
import NoProvider from './components/noProvider/NoProvider';

import 'animate.css';
import pwrdSvg from './assets/pwrd.svg';
import SVG, { Props as SVGProps } from 'react-inlinesvg';

// import logo from './logo.svg';
import appStyles from './App.module.css';
import scoreSvg from './assets/score2.svg';
import costSvg from './assets/cost2.svg';
import journySvg from './assets/journey2.svg';

function App() {
  const [navIndex, setNavIndex] = useState(0);
  const [data, setData] = useState({});
  const [provider, setProvider] = useState({});
  const [loading, setLoading] = useState(true);

  const config = {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: 'Token 518966896d8c07c8f10653f52c0aa36037d6141f',
    },
    redirect: 'follow',
  };

  useEffect(() => {
    const href = window.location.href.slice();
    const spliced = href.slice(-10);
    const parsedNPI = parseInt(spliced, 10);
    fetch(
      `https://fusion.dev.emboldhealth.com/api/pg/providers/?network_slug=widgetdemo&npi=${parsedNPI}`,
      config
    )
      .then((res) => {
        setLoading(false);
        return res.json();
      })
      .then((datum) => {
        setData(camelcaseKeys(datum, { deep: true }));
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    if (data.results) {
      setProvider(data.results[0]);
    }
  }, [data]);

  const transitions = {
    entering: {
      display: 'block',
    },
    entered: {
      opacity: 1,
      display: 'block',
    },
    exiting: {
      opacity: 0,
      display: 'block',
    },
    exited: {
      opacity: '0',
      display: 'none',
    },
  };
  const navItems = [
    {
      header: 'Score',
      el: Score,
      icon: scoreSvg,
      iconWidth: 17,
    },
    {
      header: 'Member Journey',
      el: Journey,
      icon: journySvg,
      iconWidth: 20,
    },
    {
      header: 'Cost Efficiency',
      el: Cost,
      icon: costSvg,
      iconWidth: 13,
    },
  ];
  const CurrentPanel = navItems[navIndex].el;
  return (
    <div style={{ maxWidth: '486px' }}>
      <div className={appStyles.App}>
        {loading && (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '100%',
            }}
          >
            <PulseLoader color="#003a70" size={20} speedMultiplier={1} />
          </div>
        )}
        {!loading && (!provider || !provider.scoreOverallQuality) && <NoProvider />}
        {!loading && provider && provider.scoreOverallQuality && (
          <SwitchTransition mode="out-in">
            <CSSTransition
              key={navIndex}
              classNames={{
                enterActive: 'animate__animated animate__fadeIn',
                exitActive: 'animate__animated animate__fadeOut',
              }}
              timeout={200}
            >
              <CurrentPanel
                scoreCost={provider?.scoreCareJourneyCost}
                scoreCareDiagnosing={provider?.scoreCareJourneyDiagnosing}
                scoreCareTreatment={provider?.scoreCareJourneyTreatment}
                scoreCareOutcomes={provider?.scoreCareJourneyOutcomes}
                overallScore={provider?.scoreOverallQuality}
                subspecialties={provider?.laymansSubspecialties}
              />
            </CSSTransition>
          </SwitchTransition>
        )}
        {/* <Score /> */}
        {/* <Journey /> */}
        {/* <Cost scoreCost={3} /> */}
        {!loading && provider && provider.scoreOverallQuality && (
          <Nav setNavIndex={setNavIndex} navIndex={navIndex} navItems={navItems} />
        )}
      </div>
      <SVG src={pwrdSvg} title="Powered by Emboldhealth" width="120px" style={{ float: 'right' }} />
    </div>
  );
}

export default App;
