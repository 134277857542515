import React from 'react';
import PropTypes from 'prop-types';
import s from './Nav.module.css';
import SVG, { Props as SVGProps } from 'react-inlinesvg';
import scoreSvg from '../../assets/score.svg';
import costSvg from '../../assets/cost.svg';
import journySvg from '../../assets/journey.svg';

export default function Nav({ navItems, setNavIndex, navIndex }) {
  return (
    <div className={s.wrap}>
      {navItems.map((navItem, i) => (
        <div
          className={navIndex === i ? s.navItemActive : s.navItem}
          onClick={(e) => {
            e.preventDefault();
            setNavIndex(i);
          }}
        >
          <SVG
            src={navItem.icon}
            width={navItem.iconWidth}
            className={navIndex === i ? s.iconActive : s.icon}
            // height="auto"
            title="Menu"
          />
          {/* <img src={navItem.icon} className={s.icon} alt="icon" /> */}
        </div>
      ))}
      {/* <div className={s.navItem}>
        <img src={scoreSvg} className="icon" alt="score icon" />
      </div>
      <div className={s.navItem}>
        <img src={journySvg} className="icon" alt="score icon" />
      </div>
      <div className={s.navItem}>
        <img src={costSvg} className="icon" alt="score icon" />
      </div> */}
    </div>
  );
}
Nav.propTypes = {
  setNavIndex: PropTypes.func,
  navIndex: PropTypes.number,
  navItems: PropTypes.arrayOf(PropTypes.object),
};

Nav.defaultProps = {
  setNavIndex: null,
  navIndex: 0,
  navItems: [],
};
