import React from 'react';
import PropTypes from 'prop-types';
import s from './Rating.module.css';

export default function Rating({ header, score }) {
  const descMap = {
    1: 'Poor',
    2: 'Good',
    3: 'Excellent',
    99: 'NE',
    0: 'NE',
    null: 'NE',
  };
  return (
    <div className={s.wrap}>
      <div className={s.headerWrapper}>
        <h3 className={s.header}>{header}</h3>
      </div>
      <div className={s.dotWrap}>
        <div className={score > 0 && score !== 99 ? s.dotSolid : s.dotEmpty} />
        <div className={score > 1 && score !== 99 ? s.dotSolid : s.dotEmpty} />
        <div className={score > 2 && score !== 99 ? s.dotSolid : s.dotEmpty} />
      </div>
      <h4 className={s.description}>{descMap[score] || 'NE'}</h4>
    </div>
  );
}
Rating.propTypes = {
  header: PropTypes.string,
  score: PropTypes.number,
};

Rating.defaultProps = {
  header: '',
  score: 0,
};
