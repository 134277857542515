import React from 'react';
import PropTypes from 'prop-types';
import staticStyles from './Score.module.css';
import Header from '../components/text/Text';

export default function Score({ overallScore, subspecialties }) {
  return (
    <div className={staticStyles.wrap}>
      <div className={staticStyles.leftPanel}>
        <Header text="Quality" />
        <div className={staticStyles.scoreWrap}>
          <h2 className={staticStyles.subHeader}>Overall Score</h2>
          <h3 className={staticStyles.score}>{overallScore || 'NE'}</h3>
        </div>
      </div>
      <div className={staticStyles.rightPanel}>
        <h1 className={staticStyles.header}>Specialty Areas</h1>
        <p className={staticStyles.description}>
          {subspecialties.length ? 'Most commonly practiced specialties.' : 'Not Evaluated'}
        </p>
        <ul className={staticStyles.list}>
          {subspecialties?.map((specialty, i) => {
            if (i <= 3) {
              return (
                <li className={staticStyles.listItem} key={specialty.subspecialtyId}>
                  {specialty.subspecialtyName}
                </li>
              );
            }
          })}
        </ul>
      </div>
    </div>
  );
}

Score.defaultProps = {
  overallScore: null,
  subspecialties: [],
};

Score.propTypes = {
  overallScore: PropTypes.number,
  subspecialties: PropTypes.array,
};
