import React from 'react';
import PropTypes from 'prop-types';
import staticStyles from './NoProvider.module.css';
import Header from '../text/Text';

export default function NoProvider() {
  return (
    <div>
      <div>
        <Header text="No Provider Data" />
        <div>Please Enter a valid NPI to recieve Provider Data</div>
      </div>
    </div>
  );
}

