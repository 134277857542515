import React from 'react';
import PropTypes from 'prop-types';
import s from './Cost.module.css';
import Header from '../components/text/Text';

export default function Cost({ scoreCost }) {
  const costText = {
    1: 'Less efficient providers tend to practice in higher cost facilities, order more tests and procedures, and have more office visits per patient on average.',
    2: 'Average efficiency providers tend to practice in average cost facilities, order an average number of tests and procedures, and have an average number of office visits per patient.',
    3: 'More efficient providers tend to practice in lower cost facilities, order fewer tests and procedures, and have fewer office visits per patient on average.',
  };

  return (
    <div className={s.wrap}>
      <Header text="Cost Efficiency" />
      <div className={s.bodyWrap}>
        <div className={s.ratingsWrap}>
          <div className={scoreCost === 3 ? s.ratingEfficientActive : s.ratingEfficient}>
            More Efficient
          </div>
          <div className={scoreCost === 2 ? s.ratingAverageActive : s.ratingAverage}>Average</div>
          <div className={scoreCost === 1 ? s.ratingLessEfficientActive : s.ratingLessEfficient}>
            Less Efficient
          </div>
        </div>
        <div className={s.ratingDescription}>{costText[scoreCost] || 'Not Evaluated'}</div>
      </div>
    </div>
  );
}

Cost.propTypes = {
  scoreCost: PropTypes.number,
};

Cost.defaultProps = {
  scoreCost: 0,
};
